@import "projects/petszel-shared/src/assets/css/shared-styles.css";

td#table-text-overflow {
  overflow: hidden !important;
}

/* Add this CSS to your styles.css or component-specific CSS file */
button.sa-table__btn.sa-table__btn--small.sa-table__btn--gray, .sv-action-bar-item__btn[title="Export to Excel"] {
  display: none;
}

a.primaryBtn,
a.secondaryBtnEdit,
button.secondaryBtnEdit {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.container-fluid.min-vh-100 {
  height: 100vh;
  overflow-y: scroll;
}

.fakeLink {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

.modal-body .log-body div.article img {
  width: 150px;
}


.modal-body .log-body .emailbody {
  font-family: Roboto;
  font-size: 16px;
  color: #000000;
}
.modal-body .log-body .emailbody a {
  font-family: Roboto;
  font-size: 16px;
  color: #1c7690;
  text-decoration: none;
}
.modal-body .log-body .emailbody .button {
  border-radius: 14px;
  background: #1c7690;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: white;
  height: 41px;
  width: fit-content;
  padding: 10px 30px;
}
.modal-body .log-body .emailbody table tr td img.shelter-logo {
  padding-right: 15px;
  max-width: 350px;
  width: 100%;
  height: auto;
  display: block;
}
.modal-body .log-body .emailbody table tr td img.petszel-logo {
  padding-right: 15px;
  max-width: 125px;
  width: 100%;
  height: auto;
  display: block;
}
.modal-body .log-body .emailbody > div {
  display: inline-block;
  vertical-align: middle;
}
.modal-body .log-body div.footer {
  background-color: #f0f0f0;
  padding: 20px 30px 20px 30px;
}
.modal-body .log-body div.footer p, .modal-body .log-body div.footer h5 {
  text-align: center;
}
.modal-body .log-body div.footer ul {
  text-align: center;
  list-style-position: inside;
  list-style-type: none;
}
.modal-body .log-body div.footer li {
  display: block;
}
.modal-body .log-body div.footer h5 u strong,
.modal-body .log-body div.footer li h5 u {
  color: #218faf;
  display: inline;
}
.modal-body .log-body div.footer p a u strong {
  color: #218faf;
}
.modal-body .log-body div.footer h6 {
  text-align: center;
  white-space: normal;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.modal-body .log-body div.footer h5, .modal-body .log-body div.footer p{
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.modal-body .log-body div.footer h6 u strong {
  padding-left: 2px;
  padding-right: 2px;
}
.modal-body .log-body div.footer h6 a u strong {
  color: black;
}


.dashboard-container .card .card-body {
  overflow-y: auto;
}

.form-floating > label {
  font-size: 15px !important;
}

.has-errors {

  .invalid-feedback-custom {
    color: red;
    font-size: 13px;
    line-height: 17px;
  }

  label {
    color: red !important;
  }

  .form-control {
    &.is-invalid {
      background-image: none !important;
      padding-right: .75rem !important;
    }
  }

  .form-select {
    &.is-invalid {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
    }
  }
}

.red-text {
  color: red;
}

.btn-primary-custom {
  border-radius: 14px !important;
  border: 2px solid #1C7690 !important;
  background-color: #1C7690 !important;
  color: white !important;
}

.btn-icon-disabled {
  pointer-events: none !important;
  color: grey !important;
}

.btn-icon-custom-primary {
  color: #0d6efd;
  cursor: pointer;
}

.btn-icon-custom-danger {
  color: red;
  cursor: pointer;
}
